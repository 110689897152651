// ====================================================


// ====================================================

import { Injectable, Injector } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import { EndpointFactory } from './endpoint-factory.service';
import { ConfigurationService } from './configuration.service';
import {ChangePassword} from "../models/change-password";
import {LocalStoreManager} from "./local-store-manager.service";


@Injectable()
export class AccountEndpoint extends EndpointFactory {

  
 
    private readonly _permissionsUrl: string = "/api/account/permissions";
    private readonly _validateResetPasswordConfirmUrl: string = "/api/reset/password/validate";
    private readonly _resetPasswordUrl: string = "/api/reset/password";

  private readonly _changePassword: string = "/api/account/users/changePassword";

  get changePasswordUrl() { return this.configurations.baseUrl + this._changePassword; }

    get permissionsUrl() { return this.configurations.baseUrl + this._permissionsUrl; }
    get validateResetPasswordUrl() { return this.configurations.baseUrl + this._validateResetPasswordConfirmUrl; }
    get resetPasswordUrl() { return this.configurations.baseUrl + this._resetPasswordUrl; }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector,localStorage: LocalStoreManager) {

        super(http, configurations, injector,localStorage);
    }



  getChangePasswordEndpoint(changePassword: ChangePassword): Observable<any> {
    let body = {
      currentPassword: changePassword.currentPassword,
      confirmPassword: changePassword.confirmPassword,
      newPassword: changePassword.newPassword,
    };
    return this.http.put(this.changePasswordUrl, body,{ headers: this.getRequestHeaders().headers });
  }

    getPermissionsEndpoint<T>(): Observable<any> {

        return this.http.get<T>(this.permissionsUrl, this.getRequestHeaders())
            .catch(error => {
                return this.handleError(error, () => this.getPermissionsEndpoint());
            });
    }

    validateResetPasswordTokenEndpoint(token: string): Observable<any> {
        let endpointUrl = `${this.validateResetPasswordUrl}`;

        return this.http.post(endpointUrl, { resetToken: token }, this.getAuthHeader(true))
            .map((response: Response) => {
                return response;
            })
            .catch(error => {
                return this.handleError(error, () => this.validateResetPasswordTokenEndpoint(token));
            });
    }
    getResetPasswordEndpoint(userId: string, password: string, token: string): Observable<any> {
        let endpointUrl = `${this.resetPasswordUrl}`;
        let model = { Password: password, Email: userId, ResetToken: token };
        return this.http.post(endpointUrl, JSON.stringify(model), this.getAuthHeader(true))
            .map((response: Response) => {
                return response;
            })
            .catch(error => {
                return this.handleError(error, () => this.getResetPasswordEndpoint(userId, password, token));
            });
    }

}

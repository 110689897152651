import {ResponseOkModel} from "./response-ok-model";
import {ResponseErrorModel} from "./response-error-model";
import {ResponseMessageModel} from "./response-message-model";

export class ResponseModel {
  private _data: ResponseOkModel;
  private _error: ResponseErrorModel;


  get data(): ResponseOkModel {
    return this._data;
  }

  set data(value: ResponseOkModel) {
    this._data = value;
  }

  get error(): ResponseErrorModel {
    return this._error;
  }

  set error(value: ResponseErrorModel) {
    this._error = value;
  }

}

// ====================================================


// ====================================================

export enum Gender {
    None,
    Female,
    Male
}
export class ResponseType {
  public static SUCESS = "Success";
  public static BAD = "Bad Request";
  public static ERROR = "Error";
}

export class  RoleNames {
  public static SURGEON = "Physician/Surgeon";
  public static NURSE = "Nurse";
  public static Secretary = "Administrative Assistant";
  public static NursePractitioner = "Nurse Practitioner";
  public static PhysicianAssistant= "Physician Assistant";
  public static Representative = "Representative";
  public static Administrator = "Administrator";
}
export enum QuestionType {
  DressingChange = 0,
  DefinitiveClosure,
  Followup,
  Review,
  InitialIntervention,
  ReApplication,
}
export enum PatientDateType
{
  DebridementDate = 0,
  ApplicationDate
}


import {ResponseMessageModel} from "./response-message-model";

export class ResponseErrorModel {
  private _code: number;
  private _exception: string;
  private _messages: ResponseMessageModel[];


  get code(): number {
    return this._code;
  }

  set code(value: number) {
    this._code = value;
  }

  get exception(): string {
    return this._exception;
  }

  set exception(value: string) {
    this._exception = value;
  }

  get messages(): ResponseMessageModel[] {
    return this._messages;
  }

  set messages(value: ResponseMessageModel[]) {
    this._messages = value;
  }
}

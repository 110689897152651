import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span>PolynovoBTM  &copy; {{getYear()}} <a href="https://www.csymplicity.com" target="_blank">Csymplicity Software</a></span>`,
})
export class FooterComponent {
  getYear() {
    return new Date().getUTCFullYear();
  }

}

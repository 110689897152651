// ====================================================


// ====================================================

export class User {
    // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
    constructor(id?: string, userName?: string, fullName?: string, email?: string, phoneNumber?: string, roles?: string[], countryCode?: string) {

        this.id = id;
        this.userName = userName;
        this.fullName = fullName;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.roles = roles;
        this.countryCode = countryCode;
    }

    public id: string;
    public userName: string;
    public fullName: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phoneNumber: string;
    public isEnabled: boolean;
    public isLockedOut: boolean;
    public roles: string[];
    public studyCenterId: string;
    public countryCode: string;
}

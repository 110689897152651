import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UserLogin} from "../../models/user-login.model";
import {User} from "../../models/user.model";
import {ModalDirective} from "ngx-bootstrap";
import {AlertService, MessageSeverity} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {ModalService} from "../../services/modal.service";
import {ConfigurationService} from "../../services/configuration.service";

@Component({
  selector: 'please-wait',
  templateUrl: './please-wait.component.html',
  styleUrls: ['./please-wait.component.scss']
})
export class PleaseWaitComponent implements OnInit {

  formResetToggle = true;
  loginStatusSubscription: any;
  currentUser: User;
  @Input()
  isModal = false;

  @ViewChild('loginModal')
  loginModel: ModalDirective;



  constructor(private alertService: AlertService,
              private authService: AuthService,
              private router: Router,
              private modalService: ModalService) {
  }


  ngOnInit() {
    // this.modalService.add(this);
    this.modalService.addPleaseWait(this);

    if (this.getShouldRedirect()) {
      this.loginModel.hide();
    }
    else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
        if (this.getShouldRedirect()) {
          this.loginModel.hide();
        }
        if (!isLoggedIn) {
          this.router.navigateByUrl('/login');
        }
      });
    }
  }


  ngOnDestroy() {
    if (this.loginStatusSubscription)
      this.loginStatusSubscription.unsubscribe();
  }


  getShouldRedirect() {
    return this.authService.isLoggedIn && !this.authService.isSessionExpired;
  }


  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }





  showErrorMessage() {
    this.alertService.showMessage("Error", "There are some errors in your form.", MessageSeverity.error);
  }


  open(): void {
    this.loginModel.show();
    this.alertService.stopLoadingMessage();
    this.alertService.resetStickyMessage();
  }

  // close modal
  close(): void {
    this.loginModel.hide();
  }








}

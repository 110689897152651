// ====================================================


// ====================================================

export type PermissionNames =
    "View Users" | "Manage Users" |
    "View Roles" | "Manage Roles" | "Assign Roles";

export type PermissionValues =
  "provider.dashboard.view" |
  "assign.admin" | "assign.resource" |
  "assign.rep" |
  "representative.dashboard.view" |
  "admin.dashboard.view" |
  "studyAdmin.dashboard.view" |
  "patients.manage" | "patients.view" |
  "audit.logs.view" | "operation.logs.view" |
  "woundAreas.manage" | "woundAreas.view" |
  "ethnicities.manage" | "ethnicities.view" |
  "resources.manage" | "resources.view" |
  "demographicFields.manage" | "demographicFields.view" |
  "demographicForms.manage" | "demographicForms.view" |
  "areaOfStudy.manage" | "areaOfStudy.view" |
  "answers.view" | "answers.manage" |  "wounds.view" | "wounds.manage" | "woundSubs.view" | "woundSubs.manage" |
  "users.view" | "users.manage" | "questionnaires.manage" | "questionnaires.view" | "questions.manage" | "questions.view" |
    "roles.view" | "roles.manage" | "roles.assign" | "studyCenters.view" | "studyCenters.manage" |
  "studyCenterUsers.view" | "studyCenterUsers.manage" |
  "studyCenterReps.view" | "studyCenterReps.manage" | "assignedStudyCenterReps.manage" |
  "assignedStudyCenterReps.view" | "assignedStudyCenterEthnicites.view" | "assignedStudyCenterEthnicities.manage";
export class Permission {

    public static readonly viewUsersPermission: PermissionValues = "users.view";
    public static readonly manageUsersPermission: PermissionValues = "users.manage";

    public static readonly viewRolesPermission: PermissionValues = "roles.view";
    public static readonly manageRolesPermission: PermissionValues = "roles.manage";
    public static readonly assignRolesPermission: PermissionValues = "roles.assign";

  public static readonly viewProviderDashboardPermission: PermissionValues = "provider.dashboard.view";
  public static readonly viewRepresentativeDashboardPermission: PermissionValues = "representative.dashboard.view";
  public static readonly viewStudyAdminProviderDashboardPermission: PermissionValues = "studyAdmin.dashboard.view";
  public static readonly viewAdminDashboardPermission: PermissionValues = "admin.dashboard.view";

  public static readonly viewStudyCentersPermission: PermissionValues = "studyCenters.view";
    public static readonly manageStudyCentersPermission: PermissionValues = "studyCenters.manage";
    public static readonly viewStudyCenterUserPermission: PermissionValues = "studyCenterUsers.view";
    public static readonly manageStudyCenterUsersPermission: PermissionValues = "studyCenterUsers.manage";
  public static readonly viewStudyCenterRepPermission: PermissionValues = "studyCenterReps.view";
  public static readonly manageStudyCenterRepsPermission: PermissionValues = "studyCenterReps.manage";

  public static readonly manageQuestionnairePermission: PermissionValues = "questionnaires.manage";
  public static readonly viewQuestionnairePermission: PermissionValues = "questionnaires.view";
  
  public static readonly viewQuestionPermission: PermissionValues = "questions.view";
  public static readonly manageQuestionPermission: PermissionValues = "questions.manage";
  public static readonly viewAnswersPermission: PermissionValues = "answers.view";
  public static readonly manageAnswersPermission: PermissionValues = "answers.manage";

  public static readonly viewWoundsPermission: PermissionValues = "wounds.view";
  public static readonly manageWoundsPermission: PermissionValues = "wounds.manage";

  public static readonly viewWoundSubsPermission: PermissionValues = "woundSubs.view";
  public static readonly manageWoundSubsPermission: PermissionValues = "woundSubs.manage";

  public static readonly viewAreaOfStudyPermission: PermissionValues = "areaOfStudy.view";
  public static readonly manageAreaOfStudyPermission: PermissionValues = "areaOfStudy.manage";

  public static readonly viewDemographicFormPermission: PermissionValues = "demographicForms.view";
  public static readonly manageDemographicFormPermission: PermissionValues = "demographicForms.manage";

  public static readonly viewDemographicFieldPermission: PermissionValues = "demographicFields.view";
 public static readonly manageDemographicFieldPermission: PermissionValues = "demographicFields.manage";

  public static readonly viewPatientsPermission: PermissionValues = "patients.view";
  public static readonly managePatientsPermission: PermissionValues = "patients.manage";

  public static readonly viewWoundAreasPermission: PermissionValues = "woundAreas.view";
  public static readonly manageWoundAreasPermission: PermissionValues = "woundAreas.manage";

  public static readonly viewEthnicitiesPermission: PermissionValues =   "ethnicities.view";
  public static readonly manageEthnicitiesPermission: PermissionValues = "ethnicities.manage";

  public static readonly viewResourcesPermission: PermissionValues = "resources.view";
  public static readonly manageResourcesPermission: PermissionValues = "resources.manage";
  public static readonly viewAuditLogsPermission: PermissionValues = "audit.logs.view";
  public static readonly viewOperationLogsPermission: PermissionValues = "operation.logs.view";

  public static readonly assignAdminPermission: PermissionValues = "assign.admin";
  public static readonly assignRepPermission: PermissionValues = "assign.rep";
  public static readonly assignResourcePermission: PermissionValues = "assign.resource";

  public static readonly viewAssignedStudyCenterReps: PermissionValues = "assignedStudyCenterReps.view";
  public static readonly manageAssignedStudyCenterReps: PermissionValues = "assignedStudyCenterReps.manage";
  
  public static readonly manageAssignedStudyCenterEths: PermissionValues = "assignedStudyCenterEthnicities.manage";
  public static readonly viewAssignedStudyCenterEths: PermissionValues = "assignedStudyCenterEthnicites.view";
  constructor(name?: PermissionNames, value?: PermissionValues, groupName?: string, description?: string) {
        this.name = name;
        this.value = value;
        this.groupName = groupName;
        this.description = description;
    }

    public name: PermissionNames;
    public value: PermissionValues;
    public groupName: string;
    public description: string;
}

// ====================================================


// ====================================================

import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';

import { LocalStoreManager } from './local-store-manager.service';
import { EndpointFactory } from './endpoint-factory.service';
import { ConfigurationService } from './configuration.service';
import { JwtHelper } from './jwt-helper';
import { Utilities } from './utilities';
import { LoginResponse, IdToken } from '../models/login-response.model';
import { User } from '../models/user.model';
import {Permission, PermissionValues} from '../models/permission.model';
import {MenuService} from "./menu.service";
import {
  ADMIN_MENU_ITEMS,
  ADMIN_PROVIDER_MENU_ITEMS,
  GET_MENU,
  PROVIDER_MENU_ITEMS,
  REP_MENU_ITEMS
} from "../pages/pages-menu";
import {DBkeys} from "./db-Keys";
import {HttpEvent} from "@angular/common/http";
import {ResponseModel} from "../models/response.model";
import {RoleNames} from "../models/enums";
import {Constants} from "../app.constants";

@Injectable()
export class AuthService {

    public get loginUrl() { return this.configurations.loginUrl; }
    public get homeUrl() { return this.configurations.homeUrl; }

    public loginRedirectUrl: string;
    public logoutRedirectUrl: string;

    public reLoginDelegate: () => void;

    private previousIsLoggedInCheck = false;
    private _loginStatus = new Subject<boolean>();
    private _isRefreshing = false;


  get isRefreshing(): boolean {
    return this._isRefreshing;
  }

  set isRefreshing(value: boolean) {
    this._isRefreshing = value;
  }

  constructor(private menuService:MenuService,  private router: Router, private configurations: ConfigurationService, private endpointFactory: EndpointFactory, private localStorage: LocalStoreManager) {
        this.initializeLoginStatus();
    }


    private initializeLoginStatus() {
        this.localStorage.getInitEvent().subscribe(() => {
            this.reevaluateLoginStatus();
        });
    }

     IsRetainUserSession(){
      var url = window.location.href;
       if(url.indexOf("/confirm/email/") != -1) {
         return false;
      }
      return true;
    }

    gotoPage(page: string, preserveParams = true) {

        let navigationExtras: NavigationExtras = {
            queryParamsHandling: preserveParams ? "merge" : "", preserveFragment: preserveParams
        };


        this.router.navigate([page], navigationExtras);
    }


    redirectLoginUser() {
        let redirect = this.loginRedirectUrl && this.loginRedirectUrl != '/' && this.loginRedirectUrl != ConfigurationService.defaultHomeUrl ? this.loginRedirectUrl : this.homeUrl;
        this.loginRedirectUrl = null;

        if (redirect == '/') {
          let role = this.currentUser.roles[0];
          if (role === RoleNames.Representative) {
            redirect = Constants.REPRESENTATIVEDASHBOARDURL;
          }
          else if(role === RoleNames.Administrator) {
            redirect = Constants.ADMINDASHBOARDURL;
          }
          else if(this.userPermissions.some(p => p == Permission.manageStudyCenterUsersPermission)) {
            redirect = Constants.STUDYADMINPROVIDERDASHBOARDURL;
          }
          else {
            redirect = Constants.PROVIDERDASHBOARDURL;
          }
        }

        let urlParamsAndFragment = Utilities.splitInTwo(redirect, '#');
        let urlAndParams = Utilities.splitInTwo(urlParamsAndFragment.firstPart, '?');

        let navigationExtras: NavigationExtras = {
            fragment: urlParamsAndFragment.secondPart,
            queryParams: Utilities.getQueryParamsFromString(urlAndParams.secondPart),
            queryParamsHandling: "merge"
        };

        this.router.navigate([urlAndParams.firstPart], navigationExtras);
    }


    redirectLogoutUser() {
        let redirect = this.logoutRedirectUrl ? this.logoutRedirectUrl : this.loginUrl;
        this.logoutRedirectUrl = null;

        this.router.navigate([redirect]);
    }


    redirectForLogin() {
        this.loginRedirectUrl = this.router.url;
        this.router.navigate([this.loginUrl]);
    }


    reLogin() {

        this.localStorage.deleteData(DBkeys.TOKEN_EXPIRES_IN);

        if (this.reLoginDelegate) {
            this.reLoginDelegate();
        }
        else {
            this.redirectForLogin();
        }
    }


    refreshLogin(): Observable<any> {
        this.isRefreshing = true;
        return this.endpointFactory.getRefreshLoginEndpoint<LoginResponse>(this.refreshToken)
            .map(response => this.processLoginResponse(response, this.rememberMe));
    }


    login(userName: string, password: string, rememberMe?: boolean) {

        if (this.isLoggedIn)
            this.logout();

        return this.endpointFactory.getLoginEndpoint<LoginResponse>(userName, password)
            .map(response => this.processLoginResponse(response, rememberMe));
    }
  public pauseTask(continuation: () => Observable<HttpEvent<any>>) {
    return this.endpointFactory.pauseTask(continuation);
  }


  public resumeTasks(continueOp: boolean) {
     this.endpointFactory.resumeTasks(continueOp);
  }



  private processLoginResponse(response: LoginResponse, rememberMe: boolean) {
    console.log(response);
        if (response.error) {
          throw response;
        }
        this.isRefreshing = false;
        // let response = responseModel.data.result as LoginResponse;
        let accessToken = response.access_token;

        if (accessToken == null)
            throw new Error("Received accessToken was empty");

        let idToken = response.id_token;
        let refreshToken = response.refresh_token || this.refreshToken;
        let expiresIn = response.expires_in;

        let tokenExpiryDate = new Date();
        tokenExpiryDate.setSeconds(tokenExpiryDate.getSeconds() + expiresIn);

        let accessTokenExpiry = tokenExpiryDate;

        let jwtHelper = new JwtHelper();
    let decodedIdToken = <IdToken>jwtHelper.decodeToken(response.id_token);
    console.log(decodedIdToken);
      let permissions: PermissionValues[] = Array.isArray(decodedIdToken.permission) ? decodedIdToken.permission : [decodedIdToken.permission];

        if (!this.isLoggedIn)
            this.configurations.import(decodedIdToken.configuration);
        let user = new User(
            decodedIdToken.sub,
            decodedIdToken.name,
          this.titleCaseWord(decodedIdToken.fullname[0]) + ' ' + this.titleCaseWord(decodedIdToken.fullname[1]),
            decodedIdToken.email,
            decodedIdToken.phone,
            Array.isArray(decodedIdToken.role) ? decodedIdToken.role : [decodedIdToken.role]);
        user.isEnabled = true;
        user.studyCenterId = decodedIdToken.studyCenterId;
        if(user.roles.some(p => p === 'Administrator')){
          this.menuService.menu = ADMIN_MENU_ITEMS();
        }
        else if(user.roles.some(p => p === 'Representative')){
          this.menuService.menu = REP_MENU_ITEMS();
        }
        else if(permissions.some(p => p == Permission.manageStudyCenterUsersPermission)){
          this.menuService.menu = ADMIN_PROVIDER_MENU_ITEMS(user.studyCenterId);
        }
        else {
          this.menuService.menu = PROVIDER_MENU_ITEMS();
        }
        this.saveUserDetails(user, permissions, accessToken, idToken, refreshToken, accessTokenExpiry, rememberMe);
          let refreshTokenExpiryDate = new Date();
          refreshTokenExpiryDate.setSeconds(refreshTokenExpiryDate.getSeconds() + +decodedIdToken.refresh_expire_in);
          // Adding this line for storing refresh token expiry
          this.localStorage.savePermanentData(refreshTokenExpiryDate, DBkeys.REFRESH_TOKEN_EXPIRES_IN);
        this.reevaluateLoginStatus(user);
        GET_MENU(this.menuService,this.currentUser.roles[0],this.localStorage);
      return user;
    }


  private titleCaseWord(word: string) : string {
    if (!word) return word;
    word = word.trim();
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
    private saveUserDetails(user: User, permissions: PermissionValues[], accessToken: string, idToken: string, refreshToken: string, expiresIn: Date, rememberMe: boolean) {

        if (rememberMe) {
            this.localStorage.savePermanentData(accessToken, DBkeys.ACCESS_TOKEN);
            this.localStorage.savePermanentData(idToken, DBkeys.ID_TOKEN);
            this.localStorage.savePermanentData(refreshToken, DBkeys.REFRESH_TOKEN);
            this.localStorage.savePermanentData(expiresIn, DBkeys.TOKEN_EXPIRES_IN);
            this.localStorage.savePermanentData(permissions, DBkeys.USER_PERMISSIONS);
            this.localStorage.savePermanentData(user, DBkeys.CURRENT_USER);
        }
        else {
            this.localStorage.saveSyncedSessionData(accessToken, DBkeys.ACCESS_TOKEN);
            this.localStorage.saveSyncedSessionData(idToken, DBkeys.ID_TOKEN);
            this.localStorage.saveSyncedSessionData(refreshToken, DBkeys.REFRESH_TOKEN);
            this.localStorage.saveSyncedSessionData(expiresIn, DBkeys.TOKEN_EXPIRES_IN);
            this.localStorage.saveSyncedSessionData(permissions, DBkeys.USER_PERMISSIONS);
            this.localStorage.saveSyncedSessionData(user, DBkeys.CURRENT_USER);
        }

        this.localStorage.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
    }


    getLogoutEndpoint() {
        return this.endpointFactory.getLogoutEndpoint();
    }

  logout(): void {
        this.localStorage.deleteData(DBkeys.ACCESS_TOKEN);
        this.localStorage.deleteData(DBkeys.ID_TOKEN);
        this.localStorage.deleteData(DBkeys.REFRESH_TOKEN);
        this.localStorage.deleteData(DBkeys.TOKEN_EXPIRES_IN);
        this.localStorage.deleteData(DBkeys.USER_PERMISSIONS);
        this.localStorage.deleteData(DBkeys.CURRENT_USER);

        this.configurations.clearLocalChanges();

        this.reevaluateLoginStatus();
    }


    private reevaluateLoginStatus(currentUser?: User) {

        let user = currentUser || this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
        let isLoggedIn = user != null;

        if (this.previousIsLoggedInCheck != isLoggedIn) {
            setTimeout(() => {
                this._loginStatus.next(isLoggedIn);
            });
        }

        this.previousIsLoggedInCheck = isLoggedIn;
    }


    getLoginStatusEvent(): Observable<boolean> {
        return this._loginStatus.asObservable();
    }

    sendRecoveryEmail(email: string) {
        return this.endpointFactory
            .getRecoveryEmailEndpoint(email);
    }


    get currentUser(): User {

        let user = this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
        this.reevaluateLoginStatus(user);

        return user;
    }
    get isProvider(): boolean {
        

        let isProvider = false;
        let user = this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);  

        if (user.roles.some(p => p === 'Physician/Surgeon')
            || user.roles.some(p => p === 'Nurse')
            || user.roles.some(p => p === 'Administrative Assistant')
            || user.roles.some(p => p === 'Nurse Practitioner')
            || user.roles.some(p => p === 'Physician Assistant')){
            isProvider = true;
        }
        return isProvider;
    }

    get userPermissions(): PermissionValues[] {
        return this.localStorage.getDataObject<PermissionValues[]>(DBkeys.USER_PERMISSIONS) || [];
    }

    get accessToken(): string {

        this.reevaluateLoginStatus();
        return this.localStorage.getData(DBkeys.ACCESS_TOKEN);
    }

    get accessTokenExpiryDate(): Date {

        this.reevaluateLoginStatus();
        return this.localStorage.getDataObject<Date>(DBkeys.TOKEN_EXPIRES_IN, true);
    }
  get refreshAccessTokenExpiryDate(): Date {

    this.reevaluateLoginStatus();
    return this.localStorage.getDataObject<Date>(DBkeys.REFRESH_TOKEN_EXPIRES_IN, true);
  }


    get isSessionExpired(): boolean {

        if (this.accessTokenExpiryDate == null) {
            return true;
        }

        return !(this.accessTokenExpiryDate.valueOf() > new Date().valueOf());
    }

  get isRefreshSessionExpired(): boolean {

    if (this.refreshAccessTokenExpiryDate == null) {
      return true;
    }

    return !(this.refreshAccessTokenExpiryDate.valueOf() > new Date().valueOf());
  }


    get idToken(): string {

        this.reevaluateLoginStatus();
        return this.localStorage.getData(DBkeys.ID_TOKEN);
    }

    get refreshToken(): string {

        this.reevaluateLoginStatus();
        return this.localStorage.getData(DBkeys.REFRESH_TOKEN);
    }

    get isLoggedIn(): boolean {
        return this.currentUser != null;
    }

    get rememberMe(): boolean {
        return this.localStorage.getDataObject<boolean>(DBkeys.REMEMBER_ME) == true;
    }
}

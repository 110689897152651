// ====================================================


// ====================================================

import { Injectable, Injector } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import { ConfigurationService } from './configuration.service';
import {LocalStoreManager} from "./local-store-manager.service";
import {DBkeys} from "./db-Keys";
import {CustomResponse} from "../reponse/custom-response";


@Injectable()
export class EndpointFactory {

    static readonly apiVersion: string = "1";

    private readonly _loginUrl: string = "/connect/token";
    private readonly _logoutUrl: string = "/api/v1/user/logout";

    private get loginUrl() { return this.configurations.baseUrl + this._loginUrl; }
    private readonly _recoveryEmailUrl: string = "/account/recovery/email";
    private get recoveryEmailUrl() { return this.configurations.baseUrl + this._recoveryEmailUrl; }


    private _accessToken: string;
    private taskPauser: Subject<any>;
    private isRefreshingLogin: boolean;

    // private _authService: AuthService;
    //
    // private get authService() {
    //     if (!this._authService)
    //         this._authService = this.injector.get(AuthService);
    //
    //     return this._authService;
    // }
    //
     get accessToken(): string {
      return this.localStorage.getData(DBkeys.ACCESS_TOKEN);
    }



    constructor(protected http: HttpClient, protected configurations: ConfigurationService, private injector: Injector,private localStorage: LocalStoreManager) {

    }


    getLoginEndpoint<T>(userName: string, password: string): Observable<T> {

        let header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

        let params = new HttpParams()
            .append('username', userName)
            .append('password', password)
            .append('grant_type', 'password')
            .append('granttype', 'password')
         // .append('MobileLogin', 'true')  for mobile
            .append('scope', 'openid email phone profile offline_access roles')
            .append('resource', window.location.origin);

        let requestBody = params.toString();

        return this.http.post<T>(this.loginUrl, requestBody, { headers: header });
    }


    getLogoutEndpoint<T>(): Observable<T> {

        let header = this.getRequestHeaders();
        return this.http.post<T>(this._logoutUrl, "", { headers: header.headers  });
    }

    getRefreshLoginEndpoint<T>(token: string): Observable<any> {

        let header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

        let params = new HttpParams()
            .append('refresh_token', token)
            .append('grant_type', 'refresh_token')
            .append('granttype', 'refresh_token')
            .append('scope', 'openid email phone profile offline_access roles');

        let requestBody = params.toString();

        return this.http.post<T>(this.loginUrl, requestBody, { headers: header });
    }



    protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this.accessToken,
            'Content-Type': 'application/json',
            'Accept': `application/vnd.iman.v${EndpointFactory.apiVersion}+json, application/json, text/plain, */*`,
            'App-Version': ConfigurationService.appVersion
        });

        return { headers: headers };
    }

  

    protected handleError(error, continuation: () => Observable<any>) {

        if (error.status == 401) {
            // if (this.isRefreshingLogin) {
            //     return this.pauseTask(continuation);
            // }
            //
            // this.isRefreshingLogin = true;
            //
            // return this.authService.refreshLogin()
            //     .mergeMap(data => {
            //         this.isRefreshingLogin = false;
            //         this.resumeTasks(true);
            //
            //         return continuation();
            //     })
            //     .catch(refreshLoginError => {
            //         this.isRefreshingLogin = false;
            //         this.resumeTasks(false);
            //
            //         if (refreshLoginError.status == 401 || (refreshLoginError.url && refreshLoginError.url.toLowerCase().includes(this.loginUrl.toLowerCase()))) {
            //             this.authService.reLogin();
            //             return Observable.throw('session expired');
            //         }
            //         else {
            //             return Observable.throw(refreshLoginError || 'server error');
            //         }
            //     });
        }

        if (error.url && error.url.toLowerCase().includes(this.loginUrl.toLowerCase())) {
            // this.authService.reLogin();

            return Observable.throw((error.error && error.error.error_description) ? `session expired (${error.error.error_description})` : 'session expired');
        }
        else {
            return Observable.throw(error);
        }
    }



    public pauseTask(continuation: () => Observable<any>) {
        if (!this.taskPauser)
            this.taskPauser = new Subject();
        return this.taskPauser.switchMap(continueOp => {
            return continueOp ? continuation() : Observable.throwError(
            // in place of clone:
            new HttpErrorResponse({
              error: CustomResponse(),
            }),
          );
        });
    }


    public resumeTasks(continueOp: boolean) {
        setTimeout(() => {
            if (this.taskPauser) {
                this.taskPauser.next(continueOp);
                this.taskPauser.complete();
                this.taskPauser = null;
            }
        });
    }

    getRecoveryEmailEndpoint(email: string): Observable<Object> {
        let header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

        let params = new HttpParams()
            .append('email', email);
        let requestBody = params.toString();
        return this.http.post(this.recoveryEmailUrl, requestBody, { headers: header });
    }

    protected getAuthHeader(includeJsonContentType?: boolean): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
        let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.accessToken });

        if (includeJsonContentType)
            headers = headers.append("Content-Type", "application/json");

        headers = headers.append("Accept", `application/vnd.iman.v${EndpointFactory.apiVersion}+json, application/json, text/plain, */*`);
        headers = headers.append("App-Version", ConfigurationService.appVersion);
        return { headers: headers };
    }
}

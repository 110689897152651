import { NbMenuItem } from '@nebular/theme';
import {StudyCenter} from "../models/study-center.modal";
import {MenuService} from "../services/menu.service";
import {LocalStoreManager} from "../services/local-store-manager.service";
import {RoleNames} from "../models/enums";
import {DBkeys} from "../services/db-Keys";
import {Constants} from "../app.constants";


export function ADMIN_MENU_ITEMS(): NbMenuItem[] {
  return [
    {
      title: 'Dashboard',
      icon: 'fa fa-home',
      link: Constants.ADMINDASHBOARDURL,
    },
    {
      title: 'Center',
      icon: 'fa fa-hospital-o',
      link: '/study-centers',
    },
    {
      title: 'Representative',
      icon: Constants.REP_ICON,
      link: '/study-reps',
    },
    {
      title: 'Questionnaire',
      icon: 'fa fa-question-circle-o',
      link: '/questionnaire',
    },
    {
      title: 'Wound Area',
      icon: 'fas fa-band-aid',
      link: '/wound-area',
    },
    {
      title: 'Resource',
      icon: 'fa fa-link',
      link: '/resource',
    },
    {
      title: 'Comorbidity',
      icon: 'fas fa-notes-medical',
      link: '/comorbidities',
    },
    {
      title: 'Ethnicity',
      icon: 'fas fa-users',
      link: '/ethnicity',
    },
    {
     title: 'Audit Logs',
     icon: 'fa fa-folder-open',
     link: '/log',
    },
    {
      title: 'Setting',
      icon: 'fa fa-cog',
      link: '/settings',
    },

    {
      title: 'Sign Out',
      icon: 'fa fa-sign-out',
      link: '/logout',
    },

  ];
}

export function REP_MENU_ITEMS(): NbMenuItem[] {
  return [
    {
      title: 'Dashboard',
      icon: 'fa fa-home',
      link: Constants.REPRESENTATIVEDASHBOARDURL,
    },
    {
      title: 'Center',
      icon: 'fa fa-hospital-o',
      link: '/study-centers',
    },
    {
      title: 'Setting',
      icon: 'fa fa-cog',
      link: '/settings',
    },
    {
      title: 'Sign Out',
      icon: 'fa fa-sign-out',
      link: '/logout',
    },
  ];
}
export function PROVIDER_MENU_ITEMS(): NbMenuItem[] {
 return [
   {
     title: 'Dashboard',
     icon: 'fa fa-home',
     link: Constants.PROVIDERDASHBOARDURL,
   },
   {
     title: 'Patient',
     icon: Constants.PATIENT_ICON,
     link: '/patients/0'
   },
   {
     title: 'Setting',
     icon: 'fa fa-cog',
     link: '/settings',
   },
   {
     title: 'Sign Out',
     icon: 'fa fa-sign-out',
     link: '/logout',
   },
 ];
}

export function ADMIN_PROVIDER_MENU_ITEMS(studyCenterId): NbMenuItem[] {
  return [
    {
      title: 'Dashboard',
      icon: 'fa fa-home',
      link: Constants.STUDYADMINPROVIDERDASHBOARDURL,
    },
    {
      title: 'User Management',
      icon: 'fa fa-user-o',
      link: '/user-management/'+studyCenterId,
    },
    {
      title: 'Patients',
      icon: Constants.PATIENT_ICON,
      link: '/patients/0',
    },
    {
      title: 'Wound Type',
      icon: 'fa fa-warning',
      link: '/wound/0',
    },
    {
      title: 'Settings',
      icon: 'fa fa-cog',
      link: '/settings',
    },
    {
      title: 'Sign Out',
      icon: 'fa fa-sign-out',
      link: '/logout',
    },
  ];
}
export function ADMIN_SUB_MENU_ITEMS(row : StudyCenter): NbMenuItem[] {

  // let menu : NbMenuItem = {
  //   title: 'Study Centers',
  //   icon: 'fa fa-hospital-o',
  //   link: '/study-centers',
  //   expanded: true,
  // };

  // menu.children = [];
  let menu1 = {
    title: 'Centers',
    icon: 'fa fa-hospital-o',
    link: '/study-centers',
    pathMatch: 'full',
  };
  let menu = {
    icon: 'fa fa-hospital-o',
    title: row.hospitalName,
    expanded: true,
    children: []
  };
    menu.children.push({
      title: 'Patient',
      icon: Constants.PATIENT_ICON,
      link: '/study-centers/' + row.studyCenterId + '/Patient'
    });
  menu.children.push({
    title: 'Wound Type',
    icon: 'fa fa-warning',
    link: '/study-centers/' + row.studyCenterId + '/Wound'
  });
      menu.children.push({
        title: 'User Management',
        icon: 'fa fa-user-o',
        link: '/study-center/' + row.studyCenterId + '/user-management'
      });
    // if (row.admin != null) {
    //   //DeAssign Representative
    //   menu.children.push({
    //     title: 'DeAssign Admin',
    //     icon: 'fa fa-warning',
    //     data: row,
    //   });
    // }
    //   menu.children.push({
    //     title: 'Admin',
    //     icon: 'fa fa-user-o',
    //     link: '/study-centers/' + row.studyCenterId + '/assign-admin'
    //   });
    // if (row.questionnaireId != null) {
    //   //DeAssign Representative
    //   menu.children.push({
    //     title: 'DeAssign Questionnaire',
    //     icon: 'fa fa-warning',
    //     data: row,
    //   });
    // }
      menu.children.push({
        title: 'Assign Questionnaire',
        icon: 'fa fa-question-circle-o',
        link: '/study-centers/' + row.studyCenterId + '/assign-questionnaire'
      });
      menu.children.push({
        title: 'Assign Resources',
        icon: 'fa fa-link',
        link: '/study-centers/' + row.studyCenterId + '/assign-resource'
      });
    // menu.children.push({
    //   title: 'Provider',
    //   icon: 'fa fa-user',
    //   link: '/study-center-users/' + row.studyCenterId + ''
    // });
    // menu.children.push({
    //   title: 'Edit',
    //   icon: 'fa fa-pencil-square-o',
    //   data: row,
    // });
    // menu.children.push({
    //   title: 'Delete',
    //   icon: 'fa fa-trash-o',
    //   data: row,
    // });
    menu.children.push({
      title: 'Assign Ethnicities',
      icon: 'fa fa-users',
      link: '/study-centers/' + row.studyCenterId + '/assign-ethnicities'
    });
  return [menu1,menu];
}

export function REP_SUB_MENU_ITEMS(row : StudyCenter): NbMenuItem[] {


  let menu1 = {
    title: 'Centers',
    icon: 'fa fa-hospital-o',
    link: '/study-centers',
    pathMatch: 'full',
  };
  let menu ={
    icon: 'fa fa-hospital-o',
    title: row.hospitalName,
    expanded: true,
    children: []

  };
    menu.children.push({
      title: 'Patient',
      icon: Constants.PATIENT_ICON,
      link: '/study-centers/' + row.studyCenterId + '/Patient'
    });
  return [menu1,menu];
}

function setCssToMenu() {
  setTimeout(() => {

    document.getElementsByClassName('menu-items')[0]
      .children.item(2)
      .setAttribute('style', 'background: #EEEEEE;');
    document.getElementsByClassName('menu-items')[0]
      .children.item(2).getElementsByTagName('ul')[0]
      .setAttribute('style', 'max-height: none;');

  },100);
}

export function SET_MENU(menuService: MenuService, row: StudyCenter, role: string, localStorage: LocalStoreManager) {
  if (role === RoleNames.Administrator) {
    // menuService.menu[0] = ADMIN_SUB_MENU_ITEMS(row);
    let menu = ADMIN_SUB_MENU_ITEMS(row);
    if (menuService.menu[2].title === 'Representative') {
      menuService.menu.splice(1, 0, menu[0]);
    }
    menuService.menu[2] = menu[1];
    setCssToMenu();
  }
  else {
    let menu = REP_SUB_MENU_ITEMS(row);
    if (menuService.menu[2].title === 'Setting') {
      menuService.menu.splice(1, 0, menu[0]);
    }
    menuService.menu[2] = menu[1];
    setCssToMenu();

  }
  localStorage.saveSessionData(row,DBkeys.SELECTED_STUDYCENTER);
  let rows = localStorage.getDataObject<StudyCenter[]>(DBkeys.RECENT_STUDYCENTER);
  if (rows == null) {
    rows = [];
  }
  if (rows.findIndex(p => p.studyCenterId === row.studyCenterId) < 0) {
    rows.unshift(row);
    if (rows.length > 3) {
      rows.pop();
    }
    localStorage.saveSessionData(rows,DBkeys.RECENT_STUDYCENTER);
  }
  menuService.subject.next(rows);

}

export function GET_MENU(menuService: MenuService, role: string, localStorage: LocalStoreManager) {
  setTimeout(() => {
    let row = localStorage.getDataObject<StudyCenter>(DBkeys.SELECTED_STUDYCENTER);
    if (row == null || menuService.menu === undefined)
    {
      menuService.subject.next([]);
      return;
    }
    if (role === RoleNames.Administrator) {
      // menuService.menu[0] = ADMIN_SUB_MENU_ITEMS(row);
      let menu = ADMIN_SUB_MENU_ITEMS(row);
      if (menuService.menu[2].title === 'Representative') {
        menuService.menu.splice(1, 0, menu[0]);
      }
      menuService.menu[2] = menu[1];
      let rows = localStorage.getDataObject<StudyCenter[]>(DBkeys.RECENT_STUDYCENTER);
      menuService.subject.next(rows);
      setCssToMenu();
    }
    else {
      let menu = REP_SUB_MENU_ITEMS(row);
      if (menuService.menu[2].title === 'Setting') {
        menuService.menu.splice(1, 0, menu[0]);
      }
      menuService.menu[2] = menu[1];
      let rows = localStorage.getDataObject<StudyCenter[]>(DBkeys.RECENT_STUDYCENTER);
      menuService.subject.next(rows);
      setCssToMenu();
    }
  },1000);
}

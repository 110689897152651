import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import {AuthService} from "../services/auth.service";
import {AlertService, MessageSeverity} from "../services/alert.service";
import {ModalService} from "../services/modal.service";
import {MenuService} from "../services/menu.service";
import {CustomNotOnlineResponse, CustomResponse} from "../reponse/custom-response";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    private modalService: ModalService,
    private menuService: MenuService,
    private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!window.navigator.onLine) {
      return Observable.throwError(
        // in place of clone:
        new HttpErrorResponse({
          error: CustomNotOnlineResponse(),
        }),
      );
    }
    if (this.authService.isLoggedIn) {
      if (this.authService.isRefreshSessionExpired) {
        this.modalService.openLogin();
        return Observable.from([]);
      }
      else if (this.authService.isSessionExpired && !this.authService.isRefreshing) {
        this.alertService.startLoadingMessage('Refreshing token...');
        this.modalService.openPleaseWait();
        return this.authService.refreshLogin().mergeMap((user: any) => {
          this.alertService.stopLoadingMessage();
          this.modalService.closePleaseWait();
          if (user) {
            // clone and modify the request
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${this.authService.accessToken}`
              }
            });
          }
          // this.authService.resumeTasks(true);

          return next.handle(req).catch((error: HttpErrorResponse) => {
            return this.handleError(error);
          });
        }).catch((error: HttpErrorResponse) => {
          this.alertService.stopLoadingMessage();
          this.modalService.closePleaseWait();
          this.modalService.openLogin();
          return this.handleError(error);
        });

      }
      else {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.accessToken}`
          }
        });
        return next.handle(req).catch((error: HttpErrorResponse) => {
          return this.handleError(error);
        });
      }
    }
    else {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.accessToken}`
        }
      });
      return next.handle(req).catch((error: HttpErrorResponse) => {
        return this.handleError(error);
      });
    }
  }


  private handleError(error: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (error.status == 401) {
      return Observable.throwError(
        // in place of clone:
        new HttpErrorResponse({
          error: CustomResponse(),
          headers: error.headers,
          status: error.status,
          statusText: error.statusText,
          url: error.url || undefined,
        }),
      );
    }
    else if (error.status == 400) {
      if (error.error.error_description) {
        return Observable.throwError(
          // in place of clone:
          new HttpErrorResponse({
            error: CustomResponse(error.error.error_description),
            headers: error.headers,
            status: error.status,
            statusText: error.statusText,
            url: error.url || undefined,
          }),
        );
      }
    }
    else if (error.status == 500) {
      if (error.error.error_description) {
        return Observable.throwError(
          // in place of clone:
          new HttpErrorResponse({
            error: CustomResponse(error.error.error_description),
            headers: error.headers,
            status: error.status,
            statusText: error.statusText,
            url: error.url || undefined,
          }),
        );
      }
      else {
        return Observable.throwError(
          // in place of clone:
          new HttpErrorResponse({
            error: CustomResponse(error.message),
            headers: error.headers,
            status: error.status,
            statusText: error.statusText,
            url: error.url || undefined,
          }),
        );
      }
    }
    return Observable.throwError(error);
  }
}

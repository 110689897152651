import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {UserLogin} from "../../models/user-login.model";
import {ConfigurationService} from "../../services/configuration.service";
import {ModalDirective} from "ngx-bootstrap/modal";
import {AlertService, DialogType, MessageSeverity} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {Utilities} from "../../services/utilities";
import {User} from "../../models/user.model";
import {ModalService} from "../../services/modal.service";
import {Router} from "@angular/router";

@Component({
  selector: 'dialog-login',
  templateUrl: './dialog-login.component.html',
  styleUrls: ['./dialog-login.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DialogLoginComponent implements OnInit {

  userLogin = new UserLogin();
  isLoading = false;
  isVerifyingEmail = false;
  forgotPasswordEmailText = "";
  formResetToggle = true;
  modalClosedCallback: () => void;
  loginStatusSubscription: any;
  currentUser: User;
  @Input()
  isModal = false;
  forgotPasswordReset = true;

  @ViewChild('loginModal')
  loginModel: ModalDirective;


  @ViewChild('forgotPasswordModal')
  forgotPasswordModal: ModalDirective;


  constructor(private alertService: AlertService,
              private authService: AuthService,
              private router: Router,
              private modalService: ModalService,
              private configurations: ConfigurationService) {
    this.currentUser = this.authService.currentUser;
  }


  ngOnInit() {
    // this.modalService.add(this);
    this.modalService.addLogin(this);
    this.userLogin.rememberMe = this.authService.rememberMe;

    if (this.getShouldRedirect()) {
      this.loginModel.hide();
    }
    else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
        if (this.getShouldRedirect()) {
          this.loginModel.hide();
        }
        if (!isLoggedIn) {
          this.router.navigateByUrl('/login');
        }
      });
    }
  }


  ngOnDestroy() {
    if (this.loginStatusSubscription)
      this.loginStatusSubscription.unsubscribe();
  }


  getShouldRedirect() {
    return this.authService.isLoggedIn && !this.authService.isSessionExpired;
  }


  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }




  resetForm() {
    this.userLogin = new UserLogin();
  }

  showErrorMessage() {
    this.alertService.showMessage("Error", "There are some errors in your form.", MessageSeverity.error);
  }

  onHidden(): void {
    this.alertService.resetStickyMessage();
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  login() {
    this.isLoading = true;
    this.alertService.startLoadingMessage("", "Attempting login...");

    this.authService.login(this.userLogin.email, this.userLogin.password, this.userLogin.rememberMe)
      .subscribe(
        user => {

          setTimeout(() => {
            this.loginModel.hide();
            this.alertService.stopLoadingMessage();
            this.isLoading = false;
            if (!this.isModal) {
              this.alertService.showMessage("Login", `Welcome ${user.userName}!`, MessageSeverity.success);
            }
            else {
              this.alertService.showMessage("Login", `Session for ${user.userName} restored!`, MessageSeverity.success);
              setTimeout(() => {
                this.alertService.showStickyMessage("Session Restored", "Please try your last operation again", MessageSeverity.default);
              }, 500);

            }
          }, 500);
        },
        error => {

          this.alertService.stopLoadingMessage();
          this.loginModel.hide();
          if (Utilities.checkNoNetwork(error)) {
            this.alertService.showStickyMessage(Utilities.noNetworkMessageCaption, Utilities.noNetworkMessageDetail, MessageSeverity.error, error);
          }
          else {
            let errorMessage = Utilities.findHttpResponseMessage("error_description", error);

            if (errorMessage)
              this.alertService.showStickyMessage("Unable to login", errorMessage, MessageSeverity.error, error);
            else
              this.alertService.showStickyMessage("Unable to login", "An error occurred whilst logging in, please try again later.\nError: " + error.statusText || error.status, MessageSeverity.error, error);
          }

          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
  }


  open(): void {
    this.loginModel.show();
    this.alertService.stopLoadingMessage();
    this.alertService.resetStickyMessage();
  }

  // close modal
  close(): void {
    this.resetForm();
    this.loginModel.hide();
  }








}

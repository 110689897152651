import {Component, Input, OnInit} from '@angular/core';

import {NbMenuItem, NbMenuService, NbSidebarService} from '@nebular/theme';
import {UserService} from '../../../@core/data/users.service';
import {AnalyticsService} from '../../../@core/utils/analytics.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router'
import {MenuService} from "../../../services/menu.service";
import {filter} from "rxjs/operators";
import {NbMenuBag} from "@nebular/theme/components/menu/menu.service";
import {SET_MENU} from "../../../pages/pages-menu";
import {LocalStoreManager} from "../../../services/local-store-manager.service";
import {RoleNames} from "../../../models/enums";
import {AlertService, MessageSeverity} from "../../../services/alert.service";

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {


  @Input() position = 'normal';

  user: any;

  tempMenu : NbMenuItem[] = [{ title: 'Sign out', icon: 'fa fa-sign-out', data: '/logout', }];
  adminMenu = [{ title: 'View All', data: '/study-centers' },{ title: 'Settings', icon: 'fa fa-cog', data: '/settings' }, { title: 'Sign out', icon: 'fa fa-sign-out', data: '/logout', }];
  userMenu = [{ title: 'Settings', icon: 'fa fa-cog', data: '/settings' }, { title: 'Sign out', icon: 'fa fa-sign-out', data: '/logout', }];
  finalUserMenu : NbMenuItem[] =  [];
  constructor(private sidebarService: NbSidebarService,
              private menuService: MenuService,
              private nbMenuService: NbMenuService,
              private alertService: AlertService,
              private userService: UserService,
              private analyticsService: AnalyticsService,
              private authService: AuthService,
              private localStorage: LocalStoreManager,
              private router: Router) {
  }

  setCssOfMenuItems(): void {
    let list = document.querySelectorAll('.context-menu > .menu-items > .menu-item');
    let isFirst = true;
    let iteration = list.length - 3;
    for (let i = iteration; i < list.length; i++) {
      let element = list.item(i);
      element.setAttribute('style', 'border-bottom: 1px solid #ebeef2 !important');
      if (isFirst) {
        isFirst = false;
        element.setAttribute('style', 'border-bottom: 1px solid #ebeef2 !important;font-style: italic; text-align: right;');
      }
    }
  }
  test(): void{
    setTimeout(() => {
      let popover = document.getElementsByTagName('nb-popover').item(0) as HTMLElement;
      if (popover == null) {
        return;
      }
      let width = popover.offsetWidth;
      let left = popover.offsetLeft;
      left = left - (width * 0.3);
      popover.style.left = left + 'px';
      this.setCssOfMenuItems();
    });
  }
  tag = 'my-context-menu';
  ngOnInit() {
    if (this.authService.isLoggedIn) {
      if (this.authService.currentUser.roles[0] === RoleNames.Administrator || this.authService.currentUser.roles[0] === RoleNames.Representative) {
        this.finalUserMenu = [];
        this.finalUserMenu.push(...this.adminMenu);
      }
      else {
        this.finalUserMenu = [];
        this.finalUserMenu.push(...this.userMenu);
      }
    }
    this.menuService.subject.subscribe(p => {
      this.finalUserMenu = [];
      if (p !== null) {
        p.forEach(pr => {
          this.finalUserMenu.push({
            title: pr.hospitalName,
            icon: 'fa fa-hospital-o',
            data: pr,
          });
        });
      }
      if (this.authService.isLoggedIn) {
        if (this.authService.currentUser.roles[0] === RoleNames.Administrator || this.authService.currentUser.roles[0] === RoleNames.Representative) {
          this.finalUserMenu.push(...this.adminMenu);
        }
        else {
          this.finalUserMenu.push(...this.userMenu);
        }
      }
    });
    this.nbMenuService.onItemClick()
      .pipe(filter(({ tag }) => tag === this.tag))
      .subscribe((bag : NbMenuBag) => {
        if (bag.item.data !== null) {
          if (typeof bag.item.data === 'string') {
              this.router.navigateByUrl(bag.item.data + '');
          }
          else {
            this.alertService.showMessage('Success','Study Center Selected',MessageSeverity.success);
            SET_MENU(this.menuService,bag.item.data,this.authService.currentUser.roles[0],this.localStorage);
          }
        }
      });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }
  
  goToHome() {
    if (this.isUserLoggedIn) {
      this.router.navigate(["/"]);
    } else {
      this.router.navigate(["/login"]);
    }
    
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  navigateToLogin(){
    this.router.navigate(["/login"]);
  }

  get isUserLoggedIn() {
    return this.authService.isLoggedIn;
  }

  get userName(): string {
    return this.authService.currentUser ? this.authService.currentUser.fullName : "";
  }
  get role(): string {
    return this.authService.currentUser ? this.authService.currentUser.roles[0] : "";
  }
}

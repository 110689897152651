import {ResponseErrorModel} from "../models/response-error-model";
import {ResponseMessageModel} from "../models/response-message-model";
import {ResponseModel} from "../models/response.model";

export function CustomResponse(error = ""): ResponseModel {
  let data: ResponseErrorModel = new ResponseErrorModel();
  data.code = 0;
  data.exception = '';
  data.messages =  [];
  let m = new ResponseMessageModel();
  if (error) {
    m.message = error;
  } else {
    m.message = 'Token Expired';
  }
  data.messages.push(m);
  let model = new ResponseModel();
  model.error = data;
  return model;
}

export function  CustomNotOnlineResponse(): ResponseModel {
  let data: ResponseErrorModel = new ResponseErrorModel();
  data.code = 0;
  data.exception = '';
  data.messages =  [];
  let m = new ResponseMessageModel();
  m.message = 'Internet is required';
  data.messages.push(m);
  let model = new ResponseModel();
  model.error = data;
  return model;
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private loginModal: any;
  private pleaseWaitModal: any;

  addPleaseWait(modal: any) {
    // add modal to array of active modals
    this.pleaseWaitModal = (modal);
  }

  addLogin(modal: any) {
    // add modal to array of active modals
    this.loginModal = (modal);
  }


  openLogin() {
    this.loginModal.open();
  }
  openPleaseWait() {
    this.pleaseWaitModal.open();
  }
  closeLogin() {
    this.loginModal.close();
  }
  closePleaseWait() {
    this.pleaseWaitModal.close();
  }
  close() {
    this.loginModal.close();

  }
}

import { Injectable } from '@angular/core';
import {NbMenuItem} from "@nebular/theme";
import {Subject} from "rxjs";
import {StudyCenter} from "../models/study-center.modal";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public subject = new Subject<StudyCenter[]>();
  private _menu: NbMenuItem[];
  constructor() { }


  get menu(): NbMenuItem[] {
    return this._menu;
  }

  set menu(value: NbMenuItem[]) {
    this._menu = value;
  }
}

// ====================================================


// ====================================================

import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';

import { AccountEndpoint } from './account-endpoint.service';
import { AuthService } from './auth.service';
import { User } from '../models/user.model';
import { Role } from '../models/role.model';
import { Permission, PermissionNames, PermissionValues } from '../models/permission.model';
import { UserEdit } from '../models/user-edit.model';
import {ChangePassword} from "../models/change-password";
import {ResponseModel} from "../models/response.model";
import {st} from "@angular/core/src/render3";



export type RolesChangedOperation = "add" | "delete" | "modify";
export type RolesChangedEventArg = { roles: Role[] | string[], operation: RolesChangedOperation };



@Injectable()
export class AccountService {

    public static readonly roleAddedOperation: RolesChangedOperation = "add";
    public static readonly roleDeletedOperation: RolesChangedOperation = "delete";
    public static readonly roleModifiedOperation: RolesChangedOperation = "modify";

    private _rolesChanged = new Subject<RolesChangedEventArg>();


    constructor(private router: Router, private http: HttpClient, private authService: AuthService,
        private accountEndpoint: AccountEndpoint) {

    }

  changePassword(changePassword: ChangePassword) {
    return this.accountEndpoint.getChangePasswordEndpoint(changePassword).map((response) => <ResponseModel>response);
  }
    
    userHasPermission(permissionValue: PermissionValues): boolean {
        return this.permissions.some(p => p == permissionValue);
    }
  userHasPermissionCustom(permissionValue: string): boolean {
    return this.permissions.some(p => p == permissionValue);
  }
  
  refreshLoggedInUser() {
        //return this.authService.refreshLogin();
    }
    validateResetToken(token: string) {

        return this.accountEndpoint.validateResetPasswordTokenEndpoint(token)
            .map((response: Response) => <any>response);
    }

    resetPassword(userId: string, password: string, token: string) {

        return this.accountEndpoint.getResetPasswordEndpoint(userId, password, token)
            .map((response: Response) => <any>response);
    }


    get permissions(): PermissionValues[] {
        return this.authService.userPermissions;
    }

    get currentUser() {
        return this.authService.currentUser;
    }
}
